import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import useIsIOS from "../components/Hooks/useIsIOS";
import Navigation from "../components/Navigation";
import Contact from "../components/Contact";
import { InstallPWA } from "../components/InstallPWA";
import "../styles/global.css";
import "../styles/uikit.css";

const Layout = ({ children }) => {
  const { prompt } = useIsIOS();

  useEffect(
    () => {
      try {
        const UIkit = require("uikit/dist/js/uikit");
        const Icons = require("uikit/dist/js/uikit-icons");
        UIkit.use(Icons);
      } catch (e) {
        console.error("UIKit ERROR", e);
      }
    }, []
  )

  return (
    <>
      <SEO />
      <Navigation />
      <main id="hyper-root">
        {children}
      </main>
      <Contact />
      {prompt && <InstallPWA />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
