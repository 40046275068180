import React, { Component } from 'react'
import TransitionLink, { TransitionPortal } from "gatsby-plugin-transition-link"
import gsap from 'gsap'

class AstroLink extends Component {
    constructor(props) {
        super(props)
        this.slide = this.slide.bind(this)
    }

    slide = ({ node, props: { length: seconds } }) => {
        const directionTo = '100%'
		const directionFrom = '-100%'

		const wait = seconds / 6
		const half = (seconds - wait) / 2

		const cover = document.querySelector(`.transition__cover`)

		return gsap
			.timeline()
			.set(cover, { y: 0, x: directionFrom, display: "block" })
			.to(cover, {
				x: "0%",
				ease: "power1.easeInOut",
				duration: half*0.5,
			})
			.set(node, { opacity: 0 })
			.to(
				cover,
				{
					x: directionTo,
					ease: "power1.easeIn",
					duration: half*1.5,
				},
				`+=${wait}`,
			)
    }

    moveInDirection = ({ props, node }) => {
        return this.slide({ props, node })
    }

    render() {
        const length = 1
        const {
            ...props
        } = this.props

        return (
            <>
                <TransitionLink
                    exit={{
                        length: length,
                        // delay: 0,
                        zIndex: 0,
                        trigger: ({ exit, node }) =>
                            this.moveInDirection({
                                props: exit,
                                node,
                            }),
                    }}
                    entry={{
                        zIndex: 0,
                        // length: 1,
                        delay: length/2,
                    }}
                    {...props} />

                    <TransitionPortal level="bottom">
                        <div
                        className="transition__cover"></div>
                    </TransitionPortal>
            </>
        )
    }
}

export default AstroLink