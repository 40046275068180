import React from 'react';
import AstroLink from "../../lib/AstroLink";
// import { useLocation } from "@reach/router";
import './Navigation.css';
import { LogoImage } from './LogoImage';
import { OffCanvas } from './OffCanvas';

const Navigation = () => {
    // const { pathname } = useLocation()

    return (
        <div className="nav sticky-nav">
            <div className="uk-container">
                <nav className="uk-navbar uk-navbar-container">
                    <div className="uk-navbar-center uk-hidden@s">
                        <LogoImage /></div>

                    <div className="uk-navbar-left">
                        <button
                            data-uk-toggle="target: #offcanvas-nav-primary"
                            className="uk-button-text uk-navbar-toggle uk-navbar-item uk-hidden@s uk-margin-left"
                            data-uk-navbar-toggle-icon />
                    </div>
                    <div className="uk-navbar-center uk-margin-medium-right uk-visible@s">
                        <ul className="uk-navbar-nav">
                            <li>
                                <AstroLink activeClassName="uk-active" to={'/portfolio/'}>
                                    Portfolio
                                </AstroLink>
                            </li>
                            <li>
                                <AstroLink activeClassName="uk-active" to={'/michael-makes-products/'}>
                                    Products
                                </AstroLink>
                            </li>
                            <li>
                                <LogoImage />
                            </li>
                            <li>
                                <AstroLink activeClassName="uk-active" to={'/blog/'}>
                                    Articles
                                </AstroLink>
                            </li>
                            <li>
                                <AstroLink activeClassName="uk-active" to={'/profile/'}>
                                    All About
                                </AstroLink>
                                {/* <div className="uk-navbar-dropdown uk-padding-remove" data-uk-dropdown="offset: -2; pos: top-left">
                                    <ul className="uk-nav uk-navbar-dropdown-nav">
                                        <li>
                                            <AstroLink to={'/profile/'}>Michael's Profile</AstroLink>
                                        </li>
                                        <li>
                                            <AstroLink className="" to={'/capabilities/'}>Our Capabilities</AstroLink>
                                        </li>
                                        <li>
                                            <AstroLink to={'/my-team/'}>The Team</AstroLink>
                                        </li>
                                        <li>
                                            <AstroLink className="" to={'/contact/'}>Get in touch</AstroLink>
                                        </li>
                                    </ul>
                                </div> */}
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <OffCanvas />
            </div>
    )
}
export default Navigation;
