import React from 'react';
import AstroLink from "../../lib/AstroLink";
import { useLocation } from "@reach/router";

export const OffCanvas = () => {
    const { pathname } = useLocation();
    const profilePath = ["profile", "capabilities", "resume", "contact"];

    return (
        <div id="offcanvas-nav-primary" className={`uk-animation-fade uk-animation-fast`}
            data-uk-offcanvas="overlay: false;mode: slide;selClose: .uk-close">
            <div className="uk-offcanvas-bar uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                <div
                    className="uk-position-top-left uk-padding-small uk-margin-xsmall-top uk-margin-small-left uk-close"
                    data-uk-close />
                <ul
                    className="uk-nav uk-nav-primary uk-padding-large uk-padding-remove-vertical uk-width-1-1 uk-flex uk-flex-column uk-flex-between"
                    data-uk-nav>
                    <li className="uk-margin-small">
                        <AstroLink className={`${pathname === "/" ? "uk-active uk-close" : "uk-text-blood-light"}`} to={`/`}>
                            <div className={`uk-flex uk-flex-middle uk-flex-between`}>Home</div>
                        </AstroLink>
                    </li>
                    <li className="uk-margin-small">
                        <AstroLink
                            className={`${pathname.includes("portfolio") ? "uk-active" : "uk-text-blood-light"} ${pathname === "/portfolio" ? "uk-close" : ""}`}
                            to={`/portfolio/`}>
                            <div className={`uk-flex uk-flex-middle uk-flex-between`}>Portfolio</div>
                        </AstroLink>
                    </li>
                    <li className="uk-margin-small">
                        <AstroLink
                            className={`${pathname.includes("products") ? "uk-active" : "uk-text-blood-light"} ${pathname === "/michael-makes-products" ? "uk-close" : ""}`}
                            to={`/michael-makes-products/`}>
                            <div className={`uk-flex uk-flex-middle uk-flex-between`}>Products</div>
                        </AstroLink>
                    </li>
                    <li className="uk-margin-small">
                        <AstroLink
                            className={`${pathname.includes("blog") ? "uk-active" : "uk-text-blood-light"} ${pathname === "/blog" ? "uk-close" : ""}`}
                            to={`/blog/`}>
                            <div className={`uk-flex uk-flex-middle uk-flex-between`}>Articles</div>
                        </AstroLink>
                    </li>

                    <li className="uk-margin-small">
                        <AstroLink
                            className={`${pathname.includes("profile") ? "uk-active" : "uk-text-blood-light"} ${pathname === "/profile" ? "uk-close" : ""}`}
                            to={`/profile/`}>
                            <div className={`uk-flex uk-flex-middle uk-flex-between`}>All About</div>
                        </AstroLink>
                    </li>
                    {/* <li
                        className={`uk-parent uk-margin-xsmall ${profilePath.some(p => pathname.includes(p)) ? "uk-active uk-open" : "uk-text-blood-light"}`}>
                        <AstroLink
                            className={`${profilePath.some(p => pathname.includes(p)) ? "uk-active uk-open" : "uk-text-blood-light"}`}
                            to={`/profile/`}>
                            <div className={`uk-flex uk-flex-middle uk-flex-between`}>About</div>
                        </AstroLink>
                        <ul className="uk-nav-sub">
                            <li>
                                <AstroLink
                                    className={`uk-width-5-6 ${pathname.includes("profile") ? "uk-active" : "uk-text-blood-light"} ${pathname === "/profile" ? "uk-close" : ""}`}
                                    to={`/profile/`}>
                                    <div className={`uk-flex uk-flex-middle uk-flex-between`}>Michael's Profile</div>
                                </AstroLink>
                            </li>
                            <li>
                                <AstroLink
                                    className={`uk-width-5-6 ${pathname.includes("capabilities") ? "uk-active" : "uk-text-blood-light"} ${pathname === "/rates" ? "uk-close" : ""}`}
                                    to={`/capabilities/`}>
                                    <div className={`uk-flex uk-flex-middle uk-flex-between`}>Our Capabilities</div>
                                </AstroLink>
                            </li>
                            <li>
                                <AstroLink
                                    className={`uk-width-5-6 ${pathname.includes("team") ? "uk-active" : "uk-text-blood-light"} ${pathname === "/my-team" ? "uk-close" : ""}`}
                                    to={`/my-team/`}>
                                    <div className={`uk-flex uk-flex-middle uk-flex-between`}>The Team</div>
                                </AstroLink>
                            </li>
                            <li>
                                <AstroLink
                                    className={`uk-width-5-6 ${pathname.includes("contact") ? "uk-active" : "uk-text-blood-light"} ${pathname === "/contact" ? "uk-close" : ""}`}
                                    to={`/contact/`}>
                                    <div className={`uk-flex uk-flex-middle uk-flex-between`}>Get in touch</div>
                                </AstroLink>
                            </li>
                        </ul>
                    </li> */}
                </ul>
            </div>
        </div>
    );
};
