import React from "react";
import ReactWhatsapp from 'react-whatsapp';


const WAButton = () => {
    return (
        <ReactWhatsapp
            number="+66649825564"
            message="Hi Michael"
            className="uk-button uk-button-jungle uk-border-rounded uk-text-nowrap">
                <span className="uk-flex uk-flex-middle"><span className="uk-margin-xsmall-right" data-uk-icon="icon: whatsapp; ratio: 0.8;" />WHATSAPP</span>
        </ReactWhatsapp>
    )
}

const FBButton = () => {
    return (
        <a
            href="https://m.me/michael.lisboa.10"
            className="uk-button uk-button-gun uk-border-rounded uk-text-nowrap uk-position-relative"
            target="_blank"
            rel="nofollow noreferrer">
                <span className="uk-flex uk-flex-middle"><span className="uk-margin-xsmall-right" data-uk-icon="icon: facebook; ratio: 0.8;" />MESSENGER</span>
        </a>
    )
}

const SocialContactButtons = () => {
    return (
        <div>
            <div className="uk-grid uk-grid-small uk-grid-row-small uk-grid-match uk-child-width-auto">
                <div>
                    <WAButton />
                </div>
                <div>
                    <FBButton />
                </div>
            </div>
        </div>
    )
}


export default SocialContactButtons;