import React, { useEffect, useState } from 'react';
import axios from "axios"
import { useForm } from "react-hook-form";
import Modal from "../Modal";

async function postForm(body) {
    const url = process.env.GATSBY_FORMCARRY_ENDPOINT;
    const result = await axios.post(url, body, {
        headers: {
            'Accept': 'application/json'
        }
    })

    const res = await result.data;

    return res;
}

const Form = ({ ...props }) => {
    const formData = props?.formObject ? JSON.parse(props.formObject) : null
    const {
        register,
        handleSubmit,
        errors,
        formState
    } = useForm({
        mode: "all",
    });
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isModalShown, setIsModalShown] = useState(false)
    const [responseMessage, setResponseMessage] = useState({
        subject: "Subject line",
        content: "Message content"
    })

    useEffect(
        () => {
            if (document.getElementById("SubmitContactForm") && document.getElementById("SubmitContactForm").length) {
                document.getElementById("SubmitContactForm").disabled = true;
            }
        }, [isSubmitting]
    )

    const submit = async (data, e) => {
        e.preventDefault()
        setIsSubmitting(true)

        postForm(data)
            .then(function (response) {
                console.log("POST FORM RESPONSE", response)
                setResponseMessage({
                    subject: "Got it.",
                    content: "Thanks for your email, I'll follow up with you soon."
                })
                setIsModalShown(true)
                setIsSubmitting(false)
                document.getElementById("contactForm").reset();
            })
            .catch(function (error) {
                console.log("FIRST CATCH ERROR", error)
                console.log("FIRST CATCH ERROR", error.response)
                setIsSubmitting(false)
            })

    };


    return (
        <form id="contactForm"
            className="uk-form landing-form"
            onSubmit={(e) => handleSubmit(submit)(e)}>
            <fieldset className="uk-fieldset uk-text-left">
                <div className="uk-margin-medium-top">
                    <label htmlFor="from_name" className={`${errors.from_name ? "uk-text-blood-light" : "uk-text-astro-600"}`}>
                        {errors.from_name ?
                            <span className="uk-text-blood-light uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                            :
                            null} Your name {errors.from_name?.type === "required" && "is required"}
                        <input
                            id="from_name"
                            name="from_name"
                            ref={register({ required: true })}
                            type="text"
                            className={`uk-input uk-form-large uk-border-rounded ${errors.from_name ? "uk-form-danger" : ""}`}
                            autoComplete="off"
                            autoCorrect="off"
                            required
                        />
                    </label>
                </div>

                <div className="uk-margin-medium-top">
                    <label htmlFor="from_email" className={`${errors.from_email ? "uk-text-blood-light" : "uk-text-astro-600"}`}>
                        {errors.from_email ?
                            <span className="uk-text-blood-light uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                            :
                            null} Your email {
                            errors.from_email?.type === ("required" && "is required") || (errors.from_email && errors.from_email.message)
                        }
                        <input
                            id="from_email"
                            name="from_email"
                            ref={register({
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "is invalid"
                                }
                            })}
                            type="text"
                            className={`uk-input uk-form-large uk-border-rounded ${errors.from_email ? "uk-form-danger" : ""}`}
                            autoComplete="off"
                            autoCorrect="off"
                            required
                        />
                    </label>
                </div>

                {formData?.select ?
                    <div className="uk-margin-medium-top">
                        <label htmlFor="email_topic" className={`uk-text-muted`}>
                            {errors.email_topic ?
                                <span className="uk-text-blood-light uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                                :
                                null} What are you up to?
                            <select
                                id="email_topic"
                                name="email_topic"
                                ref={register({ required: true })}
                                defaultValue={''}
                                className={`uk-select uk-form-large uk-text-truncate ${errors.email_topic ? "uk-form-danger" : ""}`}
                                required
                            >
                                <option value="" disabled={true}>Choose one</option>
                                {formData.select.options.map((copy, index) =>
                                    <option key={copy} value={copy} className="uk-width-1-1 uk-text-truncate">{copy}</option>
                                )}
                            </select>
                        </label>
                    </div>
                    : null}

                <div className="uk-margin-medium-top">
                    <label htmlFor="from_message" className={`${errors.from_message ? "uk-text-blood-light" : "uk-text-astro-600"}`}>
                        {errors.from_message ?
                            <span className="uk-text-blood-light uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                            :
                            null} Write a message {
                            errors.from_message?.type === ("required" && "is required") || (errors.from_message && errors.from_message.message)
                        }
                        <textarea
                            id="from_message"
                            name="from_message"
                            ref={register({
                                required: true,
                                minLength: {
                                    value: 20,
                                    message: '(at least 20 characters)'
                                }
                            })}
                            rows="3"
                            className={`uk-textarea uk-form-large ${errors.from_message ? "uk-form-danger" : ""}`}
                            maxLength="1000"
                            autoComplete="off"
                            autoCorrect="off"
                        />
                    </label>
                </div>

                {!isLoading ?

                    <div className="uk-text-center uk-margin-large">
                        {!formState.isValid ?
                            <span style={{ color: "#d8d8d8" }} className="uk-text-uppercase uk-text-small">Fill out required fields</span>
                            :
                            isSubmitting ?
                                <span style={{ color: "#666" }} className="uk-text-uppercase uk-text-small">Just a moment</span>
                                :
                                <button type="submit"
                                    className={`uk-button uk-button-large uk-border-rounded ${formState.isValid ? "uk-button-secondary" : "uk-button-text"}`}
                                    disabled={!formState.isValid}>{formState.isValid ? "Send your message" : "Fill out all fields"}
                                </button>
                        }
                    </div>
                    :
                    null
                }
            </fieldset>
            <Modal
                isActive={isModalShown}
                style={{ borderStyle: "none" }}
                className="fullscreen-card">
                <div className="uk-grid uk-grid-collapse uk-padding-remove uk-background-default
                    uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                    data-uk-height-viewport>
                    {isSubmitting ?
                        <div className="loader">Just a sec</div>
                        :
                        <div className="uk-width-1-3@m">
                            <div className="uk-padding-large uk-first-column">
                                <h1>{responseMessage.subject}</h1>
                                <p className="uk-text-lead">{responseMessage.content}</p>
                            </div>
                            <div className="uk-text-center uk-margin-large-bottom uk-first-column">
                                <button className="uk-button uk-button-secondary uk-modal-close"
                                    type="button" onClick={() => setIsModalShown(false)}>Okay</button>
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </form>
    )
}

export default Form;
