import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import socialBanner from "../images/og-image.png";

const keywords = "ui/ux, global ui ux design, michael lisboa, head of product, creative director, creative technology, design consultant, user experience, user experience consultant, ux design consultancy, user interface design, product design, brand strategy, brand experience, Asia user experience, full-stack, full-stack programmer, developer, startups, advertising"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
        title={seo.title}
        titleTemplate={titleTemplate}>
        <meta name="viewport" content="width=device-width, shrink-to-fit=no, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="Michael Lisboa" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={keywords} />
        <meta name="image" content={`${siteUrl}${socialBanner}`} />

        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${socialBanner}`} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />

        <meta name="twitter:image" content={`${siteUrl}${socialBanner}`} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitterUsername} />
        <link rel="canonical" href={`https://michaellisboa.com${pathname}`} />
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
