import React from "react";
import AstroLink from "../../lib/AstroLink";
import Form from "./Form";
import SocialContactButtons from "../Form/SocialContactButton";

const Contact = ({ location, form }) => {
    return (
        <footer className={`${location ? "uk-height-viewport" : "uk-background-astro-light"} uk-section uk-section-large`}>
            <div className="uk-flex uk-flex-center">
                <div className="uk-width-5-6 uk-width-4-5@m">
                    <div className="uk-grid uk-grid-medium">
                        <div className="uk-text-astro-darker uk-width-1-2@m">
                            <div className="uk-width-5-6@m">
                                <p className="uk-h6 uk-text-astro-dark uk-margin-small-bottom uk-text-uppercase">
                                    <strong>&mdash;</strong> Let's do great things
                                </p>
                                <h3 className="uk-margin-remove-top">
                                    Brand experiences that folks be all like, "holy shit it's magic!"
                                </h3>
                                <p className="uk-text-medium">We work with future-thinking brands, agencies and startups looking to create the next killer Brand Experience.</p>
                                <p className="uk-text-medium">If that sounds like you, get in touch.</p>
                                <div className="uk-margin-top">
                                <p className="uk-text-medium uk-text-bold">Message us</p>
                                    <div className="uk-margin-small"><SocialContactButtons /></div>
                                </div>
                                <div className="uk-width-1-1 uk-margin-medium-top">
                                    <div className="sc-only uk-width-1-1">
                                        <div className="uk-align-left">
                                            <a href="https://www.linkedin.com/in/lisboa/"
                                                className="uk-icon-button uk-margin-right" data-uk-icon="linkedin"><span>LinkedIn</span></a>
                                            <a href="http://michael_lisboa.dribbble.com/"
                                                className="uk-icon-button uk-margin-right" data-uk-icon="dribbble"><span>Dribbble</span></a>
                                            <a href="https://github.com/MichaelLisboa"
                                                className="uk-icon-button uk-margin-right" data-uk-icon="github"><span>GitHub</span></a>
                                            <a href="https://www.instagram.com/michael__lisboa/"
                                                className="uk-icon-button" data-uk-icon="instagram"><span>Instagram</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="contact-form" className="uk-width-expand">
                            <div className="uk-margin-medium-top">
                                <p className="uk-text-medium uk-text-bold uk-margin-remove-vertical">Email us</p>
                                <Form formObject={form?.formObject} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="uk-text-center">
                <span className="uk-text-small uk-text-light">
                    &copy;{new Date().getFullYear()} Michael Lisboa Dot Com &bull; &nbsp;
                    <AstroLink className="uk-text-link uk-link-reset" to={'/contact/'}>
                        <span data-uk-icon="icon: mail; ratio: 0.75" />&nbsp;<span style={{textDecoration: "underline"}}>Contact Us</span>!
                    </AstroLink>
                </span>
            </div>
        </footer>
    );
}

export default Contact;
