import React from 'react';
import AstroLink from "../../lib/AstroLink";
import logo from '../../images/astro-logo.svg';

export const LogoImage = () =>
    <AstroLink
        className="astro-logo uk-navbar-item"
        to={'/'}>
        <img
            src={logo}
            width="72px"
            alt="Astro Logo" />
    </AstroLink>